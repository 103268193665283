import { GatsbyBrowser as BaseGatsbyBrowser } from 'gatsby';
import React from 'react';
import { IntlProvider } from 'react-intl';

import './sentry.config';

import * as analytics from './src/analytics';
import { messages, polyfillIntl } from './src/i18n-config';
import { getLocaleFromPath } from './src/shared/routes';
import { PageContext } from './src/types/page';

import './src/layouts/styles/globalStyles.scss';

type GatsbyBrowser = BaseGatsbyBrowser<object, PageContext>;

// Polyfill Intl for legacy browsers
export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
    polyfillIntl();
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
    const locale = props.pageContext.lang;

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            {element}
        </IntlProvider>
    );
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
    updateLangCookie({ location });
    trackTimeOnPage({ location });
};

function updateLangCookie({ location }: { location: Location }) {
    const localeFromPathname = getLocaleFromPath(location.pathname);

    const langCookieMatch = document.cookie.match(/(?:^|;)[ ]*lang=([a-z]{2})[ ]*(?:$|;)/);
    const localeFromCookie = !!langCookieMatch ? langCookieMatch[1] : null;

    if (localeFromPathname !== localeFromCookie) {
        const maxAge = 60 * 60 * 24 * 365; // in seconds
        document.cookie = `lang=${localeFromPathname}; max-age=${maxAge}; path=/;`;
    }
}

let lastTimeOnPageUnregisterer: Function | null = null;

function trackTimeOnPage({ location }: { location: Location }) {
    const trackTimeOnPageRegisterer = () => {
        const eventCategory = 'time-on-page';

        const sendEvent = (eventAction, eventLabel) => {
            analytics.trackEvent(eventCategory, eventAction, eventLabel);
        };

        const timeouts: ReturnType<typeof setTimeout>[] = [];

        const sendDelayedEvent = (ms, eventAction, eventLabel) => {
            const i = setTimeout(() => {
                sendEvent(eventAction, eventLabel);
            }, ms);

            timeouts.push(i);
        };

        sendEvent('0', '0-10 seconds');
        sendDelayedEvent(11000, '1', '11-30 seconds');
        sendDelayedEvent(31000, '2', '31-60 seconds');
        sendDelayedEvent(61000, '3', '61-180 seconds');
        sendDelayedEvent(181000, '4', '181-600 seconds');
        sendDelayedEvent(601000, '5', '601-1800 seconds');
        sendDelayedEvent(1801000, '6', '1801+ seconds');

        return () => {
            timeouts.forEach((i) => {
                clearTimeout(i);
            });
        };
    };

    // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
    // reactHelmet is using requestAnimationFrame so we should use it too: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
    const trackPageView = () => {
        const pagePath = location.pathname + location.search + location.hash;

        analytics.trackPageView(pagePath);

        if (!!lastTimeOnPageUnregisterer) {
            lastTimeOnPageUnregisterer();
        }
        lastTimeOnPageUnregisterer = trackTimeOnPageRegisterer();
    };

    if ('requestAnimationFrame' in window) {
        requestAnimationFrame(() => {
            requestAnimationFrame(trackPageView);
        });
    } else {
        // simulate 2 rAF calls
        setTimeout(trackPageView, 32);
    }
}
