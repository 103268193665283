import * as posthog from './posthog';

export const CATEGORY_COMPONENT_NAVIGATION = 'Navigation';

export const CATEGORY_PAGE_MOBILE_APP = 'Home Page';
export const CATEGORY_PAGE_PRODUCT = 'Products Page';
export const CATEGORY_PAGE_MOBILE_RECRUITING = 'Mobile Recruiting Page';
export const CATEGORY_PAGE_WHITEPAPER = 'Whitepaper Page';
export const CATEGORY_PAGE_ABOUT_US = 'About Us Page';
export const CATEGORY_PAGE_CAREER = 'Career Page';
export const CATEGORY_PAGE_PRESS = 'Press Page';

export const ACTION_CLICK_HEADER_CALL_TO_ACTION = 'Click header call to action';
export const ACTION_CLICK_HEADER_ACTION = 'Click header action';
export const ACTION_CLICK_SECTION_CALL_TO_ACTION = 'Click section call to action';

export function trackPageView(pagePath: string) {
    posthog.trackPageview(pagePath);
}

export function trackEvent(
    category: string,
    action: string,
    label?: string | number | undefined,
    value?: number | undefined
) {
    const posthogProps: { label?: typeof label; value?: typeof value } = {};
    if (typeof label !== 'undefined') {
        posthogProps.label = label;
    }
    if (typeof value !== 'undefined') {
        posthogProps.value = value;
    }
    posthog.trackEvent(`${category}-${action}`, posthogProps);
}

export function cleanLabel(string) {
    return string.replace(/\s+/gm, ' ').trim();
}
